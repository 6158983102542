<template>
  <div>
    <!-- v-for="(item, index) in chats" :key="index" -->
    <el-card v-for="(item, index) in chats" :key="index" class="mt1">
      <div class="name">
        <img class="fl" :src="img" />
        <p class="fl">{{ name }} replied to you</p>
        <div class="clear"></div>
      </div>
      <p class="content mt">
        Visual China is a global Internet listed company with high-quality
        genuine pictures, videos and other visual content platforms (www.vcg.
        Com). It has in-depth cooperation with Getty Images, and has the
        copyright of the global photography creation social platform (500px.
        Com) with 13 million users and Corbis library, the third largest picture
        company in the world. It provides creators and users of "copyright
        visual content" with big data
      </p>
      <div class="mt replyContent">
        <!-- <p>{{ showUser(item.userId).name  }}</p> -->
        <p>{{ item.content }}</p>
        <p class="p1"><i class="el-icon-paperclip"></i>Daliangshan planni...</p>
      </div>
      <el-button class="replys" @click="visible = true">reply</el-button>
    </el-card>

    <!-- 按钮 -->
    <el-card class="reply mt">
      <el-button class="btnBack" @click="goBack">{{
        $t("inquiry.back")
      }}</el-button>
      <el-button class="btn" @click="reply">{{
        $t("inquiry.reply")
      }}</el-button>
    </el-card>

    <!-- 回复弹框 -->
    <el-dialog
      :title="$t('inquiry.sendInquiry')"
      :visible="visible"
      @close="visible = false"
    >
      <el-form ref="data" :rules="rules" :model="data">
        <!-- To -->
        <el-row class="list">
          <el-col :span="2">&nbsp;</el-col>
          <el-col :span="2" class="tl">To:</el-col>
          <el-col :span="2" v-if="data.userInfo"
            ><img class="form-user mt1" :src="data.userInfo.img"
          /></el-col>
          <el-col :span="18" v-if="data.userInfo">{{ data.userInfo.name }}</el-col>
        </el-row>
        <!-- 产品 -->
        <el-row class="list">
          <el-col :span="4">&nbsp;</el-col>
          <el-col :span="2" v-if="data.product"
            ><img class="form-goods mt1" :src="data.product.img"
          /></el-col>
          <el-col :span="18" v-if="data.product">{{ data.product.productName }}</el-col>
        </el-row>
        <!-- 描述 -->
        <el-form-item
          prop="content"
          :label="$t('inquiry.describe')"
          label-width="20%"
          class="list-describe"
        >
          <!-- <el-col :span="2">&nbsp;</el-col> -->
          <!-- <el-col :span="2" class="tl">{{ $t("inquiry.describe") }}:</el-col> -->

          <el-input type="textarea" :rows="5" v-model="data.content"></el-input>
        </el-form-item>

        <el-row class="list">
          <el-col :span="4">&nbsp;</el-col>
          <el-col :span="20" class="file"
            ><i class="el-icon-paperclip"></i> filess...</el-col
          >
        </el-row>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="visible = false">{{ $t("common.close") }}</el-button>
        <el-button class="done" type="primary" @click="doneReply('data')">{{
          $t("common.done")
        }}</el-button>
      </div>
    </el-dialog>

    <!-- 页码 -->
    <div class="pagination-rounded" v-if="totalRows > perPage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="perPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRows"
        jumper-label="dd"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  setStatus,
  updateData,
  renderData,
  searchLists,
} from "@/helper/api.js";
const moment = require("moment");
export default {
  data() {
    return {
      visible: false,
      content: "",
      data: [],
      chats: [],
      img: "",
      name: "",
      commodityId: "",
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageSizes: [1, 2, 3, 4, 5],
      rules: {
        content: [
          {
            required: true,
            message: this.$t("inquiry.contentRequired"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.id = this.$route.query.id;
    this.getInquiry();
    this.getStore();
    // this.inquiryLists();
  },
  methods: {
    // 店铺数据
    getStore() {
      renderData("GET", "store").then((res) => {
        this.store = res.data;
        this.storeId = res.data._id;
        this.inquiryLists(this.storeId, this.currentPage);
        // this.abc(this.storeId, this.currentPage);
        console.log(this.storeId);
      });
    },
    // 渲染商品
    inquiryLists(storeId, currentPage) {
      searchLists(
        "GET",
        "inquiry",
        "storeId=" + storeId,
        currentPage,
        "desc"
      ).then((res) => {
        this.totalRows = res.data.rows[0].chats.length;
        this.name = res.data.rows[0].userInfo.name;
        this.img = res.data.rows[0].userInfo.img;
        this.chats = res.data.rows[0].chats;
      });
    },

    // replyContent() {
    //   updateData("PUT", "inquiry", this.commodityId, {
    //     content: this.data.content,
    //     attach: "1",
    //   }).then((res) => {
    //     console.log(res);
    //   });
    // },

    getInquiry() {
      setStatus("PUT", "inquiry", this.id, -1).then((res) => {
        this.commodityId = res.data._id;
        this.data = res.data;
        this.data.createdAt = moment(res.data.createdAt).format(
          "MMM, DD YYYY HH:mm"
        );
      });
    },
    // 回复弹框
    reply() {
      this.visible = true;
    },
    // 回复
    doneReply(data) {
      this.$refs[data].validate((valid) => {
        if (valid) {
          updateData("PUT", "inquiry", this.commodityId, {
            content: this.data.content,
            attach: "1",
          }).then((res) => {
            this.chats = res.chats;
            if (res.success == true) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.visible = false;
              this.$router.push("reply?id=" + this.id);
            } else {
              this.$message({
                message: res.message,
                type: "error",
              });
            }
          });
        }
      });
    },
    goBack() {
      this.$router.push("inquiry");
    },
    showUser(id) {
      console.log(id);
      return {};
    },
    // 页码
    handleSizeChange(val) {
        this.PageSize = val;
        this.currentPage = 1;
        this.inquiryLists(this.storeId, this.currentPage);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.inquiryLists(this.storeId, this.currentPage);
    },
    // abc(storeId, currentPage) {
    //   searchLists(
    //     "GET",
    //     "inquiry",
    //     "storeId=" + storeId,
    //     currentPage,
    //     "desc"
    //   ).then((res) => {
    //     console.log(res);
    //     this.totalRows = res.data.rows[0].chats.length;
    //     console.log(this.totalRows);
    //     this.name = res.data.rows[0].userInfo.name;
    //     this.img = res.data.rows[0].userInfo.img;
    //     this.chats = res.data.rows[0].chats;
    //   });
    // },
  },
};
</script>

<style scoped>
.name {
  margin-top: 10px;
  margin-left: 10px;
}
.name img {
  width: 41px;
  height: 41px;
}
.name p {
  font-size: 15px;
  margin-top: 15px;
  margin-left: 10px;
}
.content {
  font-size: 15px;
  margin-left: 10px;
}
.replyContent {
  background: #f2f2f2;
}
.replyContent p {
  font-size: 15px;
  padding-top: 31px;
  margin-left: 31px;
  padding-bottom: 50px;
}
.replyContent .p1 {
  color: #ff0a0a;
  font-size: 10px;
}
.replys {
  font-size: 15px;
  margin-top: 30px;
  margin-left: 10px;
  color: #ff0a0a;
  margin-bottom: 30px;
  border: none;
  background: none;
}
.reply {
  background: #ffffff;
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 40px;
}
.reply .btn {
  width: 131px;
  height: 100%;
  margin-right: 41px;
  background: red;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 0%;
  border-radius: 10px;
}
.reply .btnBack {
  width: 131px;
  height: 100%;
  margin-right: 41px;
  background: #fae1e0;
  color: #ff0a0a;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 0%;
  border-radius: 10px;
}
.dialog-footer {
  margin-top: 20px;
  text-align: right;
}
.dialog-footer button {
  color: red;
  border: none;
  background: #fae1e0;
  border-radius: 10px;
}
.list {
  height: 80px;
  line-height: 80px;
  overflow: hidden;
}
.tl {
  text-align: right;
  padding-right: 10px;
}
.list .form-goods {
  width: 50px;
  height: 50px;
}
.list .form-user {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.list .file {
  color: red;
}
.list-describe {
  height: auto;
  overflow: hidden;
}
.el-form-item >>> .el-form-item__error {
  position: unset !important;
}
.pagination-rounded {
  height: 40px;
  line-height: 40px;
  width: 100%;
  display: block;
  text-align: center;
  padding-top: 10px;
  background-color: #ffffff;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}
</style>